'use strict';

module.exports = $(function () {
    var wishlistContainer = $('.wishlist-products-list.js-wishlist-products-list');
    if (wishlistContainer.length) {
        var isLoading = false;
        var products = wishlistContainer.find('.js-wishlist-product');
        var page = 2;
        $(window).on('scroll', function () {
            if (!isLoading) {
                if (products.length % 15 === 0) {
                    if ($(window).scrollTop() >= $(wishlistContainer).offset().top + $(wishlistContainer).outerHeight() - window.innerHeight) {
                        isLoading = true;
                        retrieveProducts($(wishlistContainer).data('url') + '?p=' + page);
                        products = wishlistContainer.find('.js-wishlist-product');
                        page++;
                    }
                }
            }
        });
    }

    function retrieveProducts(url) {
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                wishlistContainer.append(data);
                isLoading = false;
            },
            error: function (err) {
                // todo: handle error
                isLoading = false;
            },
        });
    }
});
