'use strict';

module.exports = $(function () {
    const { default: Swiper, A11y, Autoplay, Navigation, Pagination } = require("swiper");
    Swiper.use([Navigation, Pagination, Autoplay, A11y]);

    // Einstein Carousel configs, based on BaseCarousel 
    var configs = {
        slidesPerView: 5,
        slidesPerGroup: 5,
        centeredSlides: false,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        watchOverflow: true,
        breakpoints: {
            320: {
                slidesPerView: 1.5,
                slidesPerGroup: 1,
            },
            1024: {
                slidesPerView: 5,
                slidesPerGroup: 5,
            },
        },
    };

    //fallback #2: more mutations from CQR (only happening with 'recently-viewed' for now)
    setInterval(function () {
        $('.swiper.is-einstein-carousel:not(.swiper-initialized)').each(function () {
            var swiperEl = $(this);
            var swiperWr = swiperEl.find('.swiper-wrapper.js-swiper-wrapper');
            if (!swiperEl.hasClass('swiper-initialized') && swiperWr.children().length > 0) {
                var configs = {
                    slidesPerView: swiperEl.data('option-slidesperview') || 5,
                    slidesPerGroup: swiperEl.data('option-slidespergroupdesktop') || 5,
                    centeredSlides: swiperEl.data('option-centeredslides') || false,
                    loop: swiperEl.data('option-loop') || true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    watchOverflow: swiperEl.data('option-watchoverflow') || true,
                    breakpoints: {
                        320: {
                            slidesPerView: swiperEl.data('option-slidesperviewmobile') || 1.5,
                            slidesPerGroup: swiperEl.data('option-slidespergroup') || 1,
                        },
                        1024: {
                            slidesPerView: swiperEl.data('option-slidesperview') || 5,
                            slidesPerGroup: swiperEl.data('option-slidespergroupdesktop') || 5,
                            centeredSlides: swiperEl.data('option-centeredslidesdesktop') || false,
                        },
                    },
                };
                new Swiper(swiperEl[0], configs);
            }
        });
    }, 2000);

    /* OLD
    var handleEinsteinMutations = function (mutationList, observer) {

        for (var i = 0; i < mutationList.length; i++) {
            var mutation = mutationList[i];

            if (mutation.type == 'childList') {
                let recommendationSwiperMainWrapper;

                if ($(mutation.target).attr('id') && $(mutation.target).attr('id').indexOf('cq_recomm_slot') !== -1) {

                    recommendationSwiperMainWrapper = mutation.target.children && mutation.target.children.length
                        ? Array.from(mutation.target.children).filter((childNode, index) => $(childNode).hasClass('recommendation-swiper'))[0]
                        : null;

                } else if ($(mutation.target).hasClass('einstein-products-carousel')) {
                    // Set new observer..
                    // This is needed in order to detect container injection with id #cq_recomm_slot

                    var observer = new MutationObserver(handleEinsteinMutations);
                    observer.observe(mutation.target, { childList: true, subtree: true });

                    continue;
                }

                // Restart init when main swiper wrapper has been added to DOM
                if (recommendationSwiperMainWrapper) {
                    new Swiper(recommendationSwiperMainWrapper, configs);
                } else {
                    new Swiper('.recommedation-wrapper', configs);
                }

                // Disconnect observer once swiper has been reinitialized
                observer.disconnect();
            }
        }
    };
    var observer = new MutationObserver(handleEinsteinMutations);
    // Observe all Einstein Carousels mutations
    $carouselContainers.each(function (index, cont) {
        observer.observe(cont, { childList: true, subtree: true });
    });
    */
});